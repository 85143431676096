<template>
  <section class="relative text-white bg-black isolate overflow-hidden bg-[url(/images/reviews-bg.webp)] bg-cover bg-fixed bg-[30%_0]">
    <div class="relative px-6 mx-auto max-w-7xl lg:px-8">
      <div class="max-w-xl mx-auto text-center">
        <h2 class="pt-24 font-semibold leading-8" id="reviews"><a href="https://www.tripadvisor.com/Attraction_Review-g274707-d10898456-Reviews-Driveczech_Your_Personal_Driver-Prague_Bohemia.html" target="_blank">Tripadvisor</a> reviews</h2>
      </div>
      <ContentReviewsBlocks :five-testimonials="testimonials?.page ?? []" />
      <div class="pt-6 text-center">
        <ClientOnly>
          <button v-if="page+1 < testimonials!.totalPages" class="hover:underline" @click="nextPage">View more reviews</button>
          <template #fallback>
            <UButton to="" variant="link" color="white">All reviews</UButton>
          </template>
        </ClientOnly>
      </div>
    </div>

    <div class="container flex flex-wrap items-center justify-center py-10 gap-y-5 gap-x-10">
      <h3 class="m-0">More reviews on:</h3>
      <div class="flex flex-wrap justify-center gap-x-4">
        <a v-for="link in moreReviewslinks" :href="link.href" target="_blank" class="flex items-center">
          {{ link.title }}
          &nbsp;
          <UIcon v-for="i in Array(link.stars)" name="i-heroicons-star-solid" />
          ({{ link.reviewsAmount }})
        </a>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const page = ref(0)

const { data: testimonials } = await useAsyncData(() => {
  const start = page.value * 5
  return {
    page: useTestimonials().slice(start, start + 5),
    totalPages: Math.ceil(useTestimonials().length / 5),
  }
}, { watch: page })

function nextPage() {
  page.value++
  document.getElementById('reviews')?.scrollIntoView({ behavior: 'smooth' })
}

const moreReviewslinks = useMoreReviewsLinks()
</script>
